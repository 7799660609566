.body {
    background-color: #fff;
}
.schoolRank {
    margin-bottom: 20px;
    padding: 0 15px;
}
.schoolRank h2 {
    font-size: 15px;
    font-weight: normal;
    line-height: 30px;
}
.sourceRank {
    line-height: 30px;
}
.sourceRank span {
    margin-right: 20px;
    font-size: 13px;
    color: #999;
}
.websiteUrl {
    line-height: 30px;
}
.websiteUrl img {
    height: 13px;
    vertical-align: middle;
    margin-bottom: 3px;
    margin-right: 5px;
}
.websiteUrl span {
    font-size: 12px;
    color: #449bfe;
}
.cateRate {
    margin-top: 10px;
}
.cateRate ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
}
.cateRate ul li {
    height: 80px;
    text-align: center;
    width: 32%;
    padding-top: 10px;
}
.bgi1 {
    background-image: url('../../assets/images/schoolDetailImg/学士评估.png');
    background-size: 100% 100%;
}
.bgi2 {
    background-image: url('../../assets/images/schoolDetailImg/就业综合.png');
    background-size: 100% 100%;
}
.bgi3 {
    background-image: url('../../assets/images/schoolDetailImg/环境评级.png');
    background-size: 100% 100%;
}
.cateRate ul li span {
    color: #fff;
    font-size: 14px;
}
.cateRate ul li div {
    display: inline-block;
    margin-top: 10px;
    width: 70%;
    line-height: 20px;
    background-color: #fff;
    border-radius: 10px;
}

.shcoolProfile h2 {
    font-size: 15px;
    font-weight: normal;
    padding: 0 15px;
}
.shcoolProfile h2::before {
    content: '';
    height: 6px;
    width: 6px;
    border-radius: 3px;
    display: inline-block;
    background-color: orange;
    margin: 0 7px 3px 0;
}
.detail {
    box-shadow: 0px 1px 10px #ddd ;
    margin: 10px 15px;
    border-radius: 10px;
}
.detailContent {
    padding: 5px;
    background-color: #fff;
    text-indent: 2em;
    font-size: 14px;
    height: 48px;
    line-height: 24px;
    color: #777;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.detailContentOpen {
    padding: 5px;
    background-color: #fff;
    text-indent: 2em;
    font-size: 14px;
    line-height: 24px;
    color: #777;
}
.detailOpen {
    float: right;
    width: 70px;
    background-color: #fff;
    padding: 10px 10px 10px 0;
    text-align: right;
}
.detailOpen span {
    color: #999;
    font-size: 13px;
}
.detailOpen img {
    height: 8px;
    margin-left: 10px;
}
.envTags {
    margin-top: 10px;
    padding: 0 15px;
}
.envTags span {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    border: 1px solid #bbb;
    color: #888;
    padding: 0 10px;
    font-size: 14px;
    border-radius: 14px;
    margin-right: 5%;
    margin-bottom: 10px;
}
.imgBox ul {
    padding-left: 15px;
    list-style: none;
    /* height: 80px; */
    overflow: auto;
    white-space: nowrap;
    margin-bottom: 10px;
}
.imgBox ul::-webkit-scrollbar {
    display: none;
}
.imgBox ul li {
    display: inline-block;
    height: 70px;
    width: 130px;
    margin-right: 10px;
    border-radius: 10px;
    overflow: hidden;
}
.imgBox ul li img {
    height: 100%;
    width: 100%;
}

.fullImgBox {
    position: fixed;
    left: 0;
    top: 0;
    background-color: #000;
    z-index: 999;
    display: flex;
    align-items: center;
}
.location {
    padding: 10px 0 10px 15px;
    color: #888;
}
.mapBox {
    width: 100%;
    height: 200px;
}


.fullImgBox img {
    width: 100%;
    margin: auto;
}