.body {
    padding-top: 170px;
}
.father {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
}
.headerBgc {
    width: 100%;
    height: 130px;
    background-color: #449bfe;
    position: relative;
    text-align: center;
}
.headerBgc span {
    line-height: 50px;
    color: #fff;
    font-size: 18px;
}

.gobackImgBox {
    position: absolute;
    height: 50px;
    width: 50px;
    text-align: center;
    left: 0;
    top: 0;
    padding-top: 15px;
}
.gobackImgBox img {
    height: 20px;
}
.tabsBox {
    margin:-40px 20px 0;
    height:80px ;
    position: relative;
    background-color: #fff;
    box-shadow: 0 0 5px #aaa;
    border-radius: 10px;
}
.tabsBox ul {
    list-style: none;
}
.tabsBox ul li {
    position: relative;
    display: inline-block;
    width: 50%;
    line-height: 80px;
    font-size: 18px;
}
.tabsBox ul li:nth-child(1) {
    text-align: right;
    padding-right: 20px;
}
.tabsBox ul li:nth-child(2) {
    padding-left: 10px;
}
.tabsBox ul li:nth-child(1) div{
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #449bfe;
    right: 32px;
    bottom: 10px;
}
.tabsBox ul li:nth-child(2) div{
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #449bfe;
    left: 40px;
    bottom: 10px;
}
