.body {
    background-color: #fff;
}
.batchEnrollData {
    text-align: center;
    border-bottom: 1px solid #eee;
    padding: 10px 0 20px 0;
}
.batchEnrollData span {
    font-size: 15px;
    margin-right: 10px;
}
.batchEnrollData img {
    height: 15px;
    vertical-align: middle;
    margin-bottom: 3px;
}
.dataTitle {
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
    padding: 20px 0;
    color: #999;
    text-align: center;
}
.dataBox {
    overflow: auto;
    display: flex;
}

.dataItemTitle ul {
    list-style: none;
}
.dataItemTitle ul li {
    width: 100px;
    text-align: center;
}
.dataItemTitle ul li:nth-child(odd) {
    background-color: #EBF5FF;
}
.dataItemTitle ul li:nth-child(even) {
    height: 40px;
    padding: 4px 0;
}
.dataItemTitle ul li span {
    background-color: #fff;
    line-height: 30px;
    width: 100px;
    display: inline-block;
    box-shadow: 0 0 10px #EBF5FF;
}
.dataItemInfo {
    overflow: auto;
}
.dataItemInfo::-webkit-scrollbar{
    display: none;
}
.dataItemInfo ul {
    list-style: none;
}
.dataItemInfo ul li:nth-child(odd) {
    background-color: #EBF5FF;
}
.dataItemInfo ul li:nth-child(even) {
    height: 40px;
}
.dataItemInfo ul li span{
    display: inline-block;
    color: #6ab4fd;
    width: 120px;
    line-height: 30px;
    text-align: center;
}
.dataItemInfo ul li:nth-child(even) span{
    display: inline-block;
    width: 120px;
    line-height: 40px;
    text-align: center;
}
.dataItemInfo ul li div{
    display: inline-block;
    width: 120px;
    font-size: 12px;
    color: #6ab4fd;
    margin: auto;
    line-height: 1;
    text-align: center;
}
.dataItemInfo ul li:nth-child(1) span {
    color: #333;
}
.nodata {
    text-align: center;
    padding-top: 50px;
    color: #999;
}

.reference {
    font-size: 12px;
    color: #aaa;
    padding: 10px 0;
    text-align: center;
}

