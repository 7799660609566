.body {
    background-color: #fff;
    padding-top: 50px;
}
.header {
    position: fixed;
    width: 100%;
    background-color: #449bfe;
    height: 50px;
    text-align: center;
    left: 0;
    top: 0;
    z-index: 999;
}
.header span {
    line-height: 50px;
    color: #fff;
    font-size: 17px;
}
.gobackBox {
    height: 50px;
    width: 50px;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    padding-top: 15px;
}
.gobackBox img {
    height: 20px;
}

.listBox {
    padding: 10px;
}
.dynamicBox {
    padding: 20px 15px;
}
.dynamicTitle {
    line-height: 40px;
    display: flex;
    justify-content: space-between;

}
.title {
    font-size: 15px;
    font-weight: 600;
}
.title i {
    font-style: normal;
    font-size: 12px;
    margin-left: 10px;
}
.title::before{
    content: '';
    height: 16px;
    width: 4px;
    display: inline-block;
    background-color: #449bfe;
    margin: 0 8px -2px 0;
    border-radius: 2px;
}
.more {
    font-size: 13px;
    color: #449bfe;
}
.dynamicItem {
    padding: 15px 0;
    border-bottom: 1px solid #f0f0f0;
}
.itemTitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
.schoolName {
    font-size: 15px;
    font-weight: 600;
    color: #333;
}
.time {
    font-size: 12px;
    color: #aaa;
}
.dynamicContent {
    display: flex;
    padding: 10px 0 ;
}
.contentInfo {
    flex:1,
}
.contentInfo p {
    text-indent: 2em;
    color: #444;
    height: 48px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 14px;
}
.contentInfo span {
    height: 24px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 12px;
    color: #999;
}
.contentImgBox {
    height: 72px;
    width: 72px;
    margin-left: 10px;
}
.contentImgBox img {
    height: 72px;
    width: 72px;
}
.nodata {
    text-align: center;
    padding-top: 50px;
    color: #999;
}