.headerBgc {
    width: 100%;
    height: 150px;
    background-color: #449bfe;
    position: relative;
    text-align: center;
}
.headerBgc span {
    line-height: 50px;
    color: #fff;
    font-size: 16px;
}

.gobackImgBox {
    position: absolute;
    height: 50px;
    width: 50px;
    text-align: center;
    left: 0;
    top: 0;
    padding-top: 15px;
}
.gobackImgBox img {
    height: 20px;
}

.score {
    margin: -60px 15px 50px 15px;
    height: 100px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 20px 15px;
    box-shadow: 0px 2px 5px #eee;
    position: relative;
}
.scoreLeft {
    display: flex;
}
.scoreImgBox {
    float: left;
    height: 60px;
    width: 60px;
    margin-right: 20px;
}
.scoreImgBox img {
    height: 60px;
    width: 60px;
}
.scoreAndLocal h2 {
    font-weight: normal;
    font-size: 17px;
    margin-bottom: 10px;
}
.scoreAndLocal input {
    width: 70px;
    height: 30px;
    border: none;
    font-size: 18px;
    color: #e97910;
}
.editScore {
    float: right;
    line-height: 60px;
}
.editScore img {
    width: 12px;
    margin-right: 5px;
}
.editScore span {
    font-size: 13px;
    color: #999;
}

.chooseBox {
    position: relative;
    height: 60px;
    border: 1px solid #eee;
    margin: 30px 15px;
    padding-left: 60px;
    box-shadow: 0 2px 8px #eee;
    border-radius: 10px;
}
.imgBox {
    position: absolute;
    height: 60px;
    width: 60px;
    text-align: center;
    left: 0;
    top: 0;
}
.imgBox img {
    height: 30px;
    margin-top: 15px;
}
.rightIconBox {
    position: absolute;
    height: 60px;
    width: 50px;
    text-align: center;
    padding-top: 17px;
    right: 0;
    top: 0;
}
.rightIconBox img {
    height: 26px;
}
.chooseBox span {
    display: inline-block;
    line-height: 60px;
    font-size: 16px;
    color: #888;
}

.dataListBox {
    position: absolute;
    left: 0;
    width: 100%;
    background-color: #fff;
    overflow: auto;
    z-index: 10;
}
.dataListBox ul {
    list-style: none;
    padding-left: 50px;
    border: 1px solid #eee;
}
.dataListBox ul li {
    height: 40px;
    line-height: 40px;
}

.submitBox {
    height: 60px;
    border: 1px solid #eee;
    margin: 30px 15px;
    box-shadow: 0 2px 8px #eee;
    border-radius: 10px;
    background-color: #449bfe;
    text-align: center;
}
.submitBox span {
    line-height: 60px;
    color: #fff;
    font-size: 18px;
    letter-spacing: 5px;
}