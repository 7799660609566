.body {
    background-color: #fff;
}
.header {
    position: fixed;
    width: 100%;
    background-color: #449bfe;
    height: 50px;
    text-align: center;
    left: 0;
    top: 0;
    z-index: 999;
}
.header span {
    line-height: 50px;
    color: #fff;
    font-size: 17px;
}
.gobackBox {
    height: 50px;
    width: 50px;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    padding-top: 15px;
}
.gobackBox img {
    height: 20px;
}
.activeCard{
    background-color: '#fff';
    background-image: url('../../assets/images/schoolDetailImg/check.png');
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 48px;
    color:#dd2222
}
.activeCardName,.activeCardDate{
    color:#469CFE
}
.normalCard{
    background-color: #469CFE;
    border:1px solid #fff;
    color: #fff;
}
.orderBtn{
    height: 60px;
    width:100%;
    background-image: url('../../assets/images/schoolDetailImg/price.png');
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 100% 60px;
}
.activeCardTab{
    background-color:#469CFE ;
    color:#fff;
    border:1px solid #469CFE;
    padding: 0px;
}
.normalCardTab{
    border:1px solid #ccc;
    color: #000;
    padding: 0px;
}