.body {
    background-color: #fff;
    padding-top: 50px;
}
.header {
    position: fixed;
    width: 100%;
    background-color: #449bfe;
    height: 50px;
    text-align: center;
    left: 0;
    top: 0;
    z-index: 999;
}
.header span {
    line-height: 50px;
    color: #fff;
    font-size: 17px;
}
.gobackBox {
    height: 50px;
    width: 50px;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    padding-top: 15px;
}
.gobackBox img {
    height: 20px;
}
.queryTerms {
    height: 100px;
    padding: 0 15px 20px 15px;
    background-image: url('../../assets/images/collegeInquiryImg/头部投影.png');
    background-size: 100% 100%;
    position: relative;
}
.conditionCate {
    background-color: #fff;
    height: 100%;
    list-style: none;
    display: flex;
    justify-content: space-around;
    border-radius: 10px 10px 10px 10px;
}

.conditionCate li {
    margin-top: 24px;
    height: 32px;
    width: 30%;
    padding: 0 15px;
    border:1px solid #449bfe;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
}
.conditionCate li span {
    line-height: 30px;
    color: #449bfe;
    font-size: 16px;
    overflow: hidden;
}
.conditionCate li img {
    height: 6px;
    width: 10px;
    vertical-align: middle;
    margin-top: 13px;
}
.spring {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    margin-left: 0px;
    margin-right: 0px;
    left: 15px;
    right: 15px;
    top: 70px;
    overflow: hidden;
}
.queryList {
    background-color: #fff;
    overflow: auto;
    width: 100%;
    height: 200px;
   
}
.queryList ul {
    list-style: none;
    padding-left: 6px ;
    margin-bottom: 6px;
    width: 100%;
}
.queryList ul li {
    height: 30px;
    line-height: 30px;
    width: 30.2%;
    float: left;
    text-align: center;
    border:1px solid #449bfe;
    margin-bottom: 10px;
    margin-right: 10px;
    overflow: hidden;
    background-color: #449bfe;
    color:#fff;
    border-radius: 15px;
    font-size: 12px;
}
.schoolList {
    
   margin-top: 10px;
}
.schoolList ul {
    list-style: none;
}
.schoolList ul li {
    height: 140px;
    padding: 0 15px 20px 15px;
    background-image: url('../../assets/images/collegeInquiryImg/院校列表的投影.png');
    background-size: 100% 100%;
}
.allClude {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    height: 120px;
    padding: 5px 10px 15px 10px;
}
.flexLeft {
    display: flex;
}
.logoBox {
    width: 100px;
    height: 100px;
    margin-right: 10px;
}
.logoBox img {
    width: 100px;
    height: 100px;
}
.titleAndRank {
    height: 40px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
.titleAndRank span {
    font-weight: 600;
    font-size: 16px;
}
.schoolRank span {
    line-height: 40px;
}
.schoolTags {
    margin-bottom: 5px;
}
.schoolTags span {
    display: inline-block;
    margin-bottom: 3px;
    font-size: 12px;
    padding: 2px 7px;
    background-color: #449bfe;
    color: #fff;
    border-radius: 10px;
    margin-right: 5px;
}
.schoolCateLocal span:nth-child(1) img {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    vertical-align: middle;
    margin-bottom: 2px;
}
.schoolCateLocal span:nth-child(2) img {
    width: 11px;
    height: 14px;
    margin-right: 5px;
    vertical-align: middle;
    margin-bottom: 5px;
}
.schoolCateLocal span {
    color: #999;
    font-size: 12px;
    margin-right: 15px;
}
.schoolRank img {
    width: 11px;
    height: 13px;
    margin-right: 5px;
    vertical-align: middle;
    margin-bottom: 3px;
}
.schoolRank span {
    color: #feb444;
}