.body {
    background-color: #fff;
}
.header {
    width: 100%;
    height: 120px;
    background-color: #449bfe;
    display: flex;
}
.header span {
    color: #fff;
    line-height: 50px;
    width: 32px;
}
.searchBox {
    flex: 1;
    margin: 10px 15px 0 15px;
    height: 30px;
    position: relative;
    background-color: #ddd;
    border-radius: 15px;
    padding: 5px 15px 0 40px;
}
.searchBox img {
    position: absolute;
    height: 15px;
    left: 15px;
    top: 8px;
}
.searchBox input {
    width: 100%;
    height: 22px;
    font-size: 14px;
    border: none;
    background-color: #ddd;
    outline: none;
}
.searchBox input::-webkit-input-placeholder {
    color: #fff;
}
.score {
    margin: -60px 15px 0 15px;
    height: 110px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 20px 15px;
    box-shadow: 0px 2px 5px #eee;
}
.scoreLeft {
    display: flex;
}
.scoreImgBox {
    float: left;
    height: 60px;
    width: 60px;
    margin-right: 20px;
}
.scoreImgBox img {
    height: 60px;
    width: 60px;
}
.scoreAndLocal h2 {
    font-weight: normal;
    font-size: 17px;
    margin-bottom: 10px;
}
.scoreAndLocal h2 i {
    font-style: normal;
    font-size: 21px;
    color: #e97910;
    margin-left: 5px;
}
.scoreAndLocal h4 i {
    font-style: normal;
    color: #e97910;
    margin-left: 5px;
}
.scoreAndLocal span {
    font-size: 12px;
}
.editScore {
    float: right;
    line-height: 60px;
}
.editScore img {
    width: 12px;
    margin-right: 5px;
}
.editScore span {
    font-size: 13px;
    color: #999;
}

.cxlb {
    margin: 30px 15px 0 15px;
    height: 150px;
    display: flex;
    justify-content: space-between;
}
.queryAndAnalysis {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.fenshuSearch {
    height: 150px;
    border: 1px solid #ccc;
    width: 48%;
    background-image: url('../../assets/images/homeImg/智能匹配.png');
    background-size: 100% 100%;
    border-radius: 10px;
    text-align: center;
}
.fenshuSearch h2 {
    color: #fff;
    margin-top: 20px;
    line-height: 40px;
}
.fenshuSearch span {
    color: #fff;
    margin-top: 10px;
}
.gjfscx {
    height: 150px;
    width: 100%;
}
.schoolQuery {
    height: 70px;
    width: 100%;
    background-image: url('../../assets/images/homeImg/schoolQuery.png');
    background-size: 100% 100%;
    border-radius: 10px;
    padding: 10px;
    color: #fff;
}
.schoolQuery h2 {
    font-size: 17px;
}
.schoolQuery span {
    font-size: 12px;
}
.specialAnalysis {
    width: 100%;
    height: 70px;
    background-image: url('../../assets/images/homeImg/specialAnalysis.png');
    background-size: 100% 100%;
    border-radius: 10px;
    padding: 10px;
    color: #fff;
}
.specialAnalysis h2 {
    font-size: 17px;
}
.specialAnalysis span {
    font-size: 12px;
}

.dynamicBox {
    padding: 20px 15px;
}
.dynamicTitle {
    line-height: 40px;
    display: flex;
    justify-content: space-between;

}
.title {
    font-size: 15px;
    font-weight: 600;
}
.title i {
    font-style: normal;
    font-size: 12px;
    margin-left: 10px;
}
.title::before{
    content: '';
    height: 16px;
    width: 4px;
    display: inline-block;
    background-color: #449bfe;
    margin: 0 8px -2px 0;
    border-radius: 2px;
}
.more {
    font-size: 13px;
    color: #449bfe;
}
.dynamicItem {
    padding: 15px 0;
    border-bottom: 1px solid #f0f0f0;
}
.itemTitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
.schoolName {
    font-size: 15px;
    font-weight: 600;
    color: #333;
}
.time {
    font-size: 12px;
    color: #aaa;
}
.dynamicContent {
    display: flex;
    padding: 10px 0 ;
}
.contentInfo {
    flex:1,
}
.contentInfo p {
    text-indent: 2em;
    color: #444;
    height: 48px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 14px;
}
.contentInfo span {
    height: 24px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 12px;
    color: #999;
}
.contentImgBox {
    height: 72px;
    width: 72px;
    margin-left: 10px;
}
.contentImgBox img {
    height: 72px;
    width: 72px;
}