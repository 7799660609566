.body {
    background-color: #fff;
    position: relative;
}
.batchEnrollPlan {
    text-align: center;
    border-bottom: 1px solid #eee;
    padding: 10px 0 10px 0;
}
.batchEnrollPlan span {
    font-size: 15px;
    margin-right: 10px;
}
.batchEnrollPlan img {
    height: 15px;
    vertical-align: middle;
    margin-bottom: 3px;
}
.batchEnrollPlan div {
    line-height: 30px;
    text-align: center;
    color: #999;
}
.cludeAll {
    padding-bottom: 10px;
}
.planBox {
    margin: 20px 15px 0 15px;
    box-shadow: 0 0 8px #ccc;
}

.majorTitle {
    padding: 15px;
    display: flex;
    border-bottom: 1px solid #eee;
}
.majorTitle div:nth-child(1) {
    flex:1;
    font-size: 16px;
}
.majorTitle div:nth-child(2) {
    float: right;
    width: 70px;
    text-align: right;
    color: #999;
}
.majorTitle div:nth-child(2) i {
    font-style: normal;
}
.majorInfo {
    line-height: 40px;
    padding: 0 15px;
}
.majorInfo span{
    margin-right: 10px;
    color: #999;
}
.majorInfo span i {
    font-style: normal;
}
.majorInfo span:last-child{
    margin-right: 0;
}
.price {
    float: right;
}
.price em {
    font-size: 16px;
    color: #e97910;
    font-style: normal;
}

.spring {
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    left: 0;
    top: 0;
}
.dataBox {
    background-color: #fff;
    width: 80%;
    margin-left: 10%;
    padding: 20px;
    margin-top: 200px;
    border-radius: 10px;
    
}
.dataBox ul {
    list-style: none;
    max-height: 200px;
    overflow: auto;
}
.dataBox ul::-webkit-scrollbar {
    display: none;
}
.dataBox ul li {
    text-align: center;
    line-height: 30px;
    margin: 10px 0;
    border-radius: 10px;
    background-color: #fff;
    color: #fff;
    background-color: #449bfe;
}
.nodata {
    text-align: center;
    padding-top: 50px;
    color: #999;
}
.reference {
    font-size: 12px;
    color: #aaa;
    padding: 10px 0;
    text-align: center;
}