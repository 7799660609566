.body {
    padding-top: 0px;
}
.header {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    background-color: #449bfe;
    height: 50px;
    text-align: center;
    z-index: 999;
}
.header span {
    line-height: 50px;
    color: #fff;
    font-size: 17px;
}
.gobackBox {
    height: 50px;
    width: 50px;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    padding-top: 15px;
}
.gobackBox img {
    height: 20px;
}
.schoolTitle {
    height: 160px;
    padding: 0 15px 20px 15px;
    background-image: url('../../assets/images/collegeInquiryImg/院校列表的投影.png');
    background-size: 100% 100%;
}
.allClude {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    height: 140px;
    padding: 25px 10px 15px 10px;
}
.flexLeft {
    display: flex;
}
.logoBox {
    width: 100px;
    height: 100px;
    margin-right: 10px;
}
.logoBox img {
    width: 100px;
    height: 100px;
}
.titleAndRank {
    height: 40px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
.titleAndRank span {
    font-weight: 600;
    font-size: 16px;
}
.schoolRank span {
    line-height: 40px;
}
.schoolTags {
    margin-bottom: 5px;
}
.schoolTags span {
    display: inline-block;
    font-size: 12px;
    margin-bottom: 3px;
    padding: 2px 7px;
    background-color: #449bfe;
    color: #fff;
    border-radius: 10px;
    margin-right: 5px;
}
.schoolCateLocal span:nth-child(1) img {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    vertical-align: middle;
    margin-bottom: 2px;
}
.schoolCateLocal span:nth-child(2) img {
    width: 11px;
    height: 14px;
    margin-right: 5px;
    vertical-align: middle;
    margin-bottom: 5px;
}
.schoolCateLocal span {
    color: #999;
    font-size: 12px;
    margin-right: 15px;
}
.schoolRank img {
    width: 11px;
    height: 13px;
    margin-right: 5px;
    vertical-align: middle;
    margin-bottom: 3px;
}
.schoolRank span {
    color: #feb444;
}
.tabsCate {
    margin-top: 20px;
}
.tabsCate ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
}
.tabsCate ul li {
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.tabsCate ul li img {
    height: 3px;
    width: 20px;
    margin-left: 5px;
}

.detailContent {
    padding-top: 20px;
}

.spring {
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    left: 0;
    top: 0;
}
.admitBatchBox {
    background-color: #fff;
    width: 80%;
    margin-left: 10%;
    padding: 20px;
    margin-top: 200px;
    border-radius: 10px;
    
}
.admitBatchBox ul {
    list-style: none;
    max-height: 200px;
    overflow: auto;
}
.admitBatchBox ul::-webkit-scrollbar {
    display: none;
}
.admitBatchBox ul li {
    text-align: center;
    line-height: 30px;
    margin: 10px 0;
    border-radius: 10px;
    background-color: #fff;
    color: #fff;
    background-color: #449bfe;
}