.body {
    background-color: #fff;
}
.body ul {
    list-style: none;
}
.body ul li {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #eee;
}
.body ul li img {
    margin-right: 10px;
    vertical-align: middle;
}
.rightTotal {
    float: right;
    width: 50px;
    text-align: center;
}
