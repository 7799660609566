.body {
    padding-top: 170px;
}
.father {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
}
.headerBgc {
    width: 100%;
    height: 130px;
    background-color: #449bfe;
    position: relative;
    text-align: center;
}
.inputBox {
    position: relative;
    /* background-color: red; */
    height: 50px;
    padding: 10px 20px 10px 60px;
}
.input {
    width: 100%;
    height: 30px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.6);
    position: relative;
    padding: 0 15px 0 40px;
}
.input img {
    position: absolute;
    left: 10px;
    top: 5px;
    width: 20px;
}
.input input {
    width: 100%;
    height: 24px;
    margin-top: 3px;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    font-size: 16px;
    color: #fff;
}   

.input input::-webkit-input-placeholder {
    color: #fff;
}

.gobackImgBox {
    position: absolute;
    height: 50px;
    width: 50px;
    text-align: center;
    left: 0;
    top: 0;
    padding-top: 15px;
}
.gobackImgBox img {
    height: 20px;
}
.tabsBox {
    margin:-40px 20px 0;
    height:80px ;
    position: relative;
    background-color: #fff;
    box-shadow: 0 0 5px #aaa;
    border-radius: 10px;
}
.tabsBox ul {
    list-style: none;
}
.tabsBox ul li {
    position: relative;
    display: inline-block;
    width: 50%;
    line-height: 80px;
    font-size: 18px;
    text-align: center;
}
.tabsBox ul li img {
    position: absolute;
    left: 50%;
    margin-left: -10px;
    bottom: 10px;
    width: 20px;
}
