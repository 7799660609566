.body {
    background-color: #fff;
}
.quickFind {
    padding-left: 15px;
    margin-top: 10px;
}
.quickFind span {
    line-height: 50px;
    color: #999;
}
.findCondition {
    padding-left: 15px;
}
.conditionItem {
    padding-bottom: 20px;
}
.conditionItem h2 {
    font-size: 15px;
    font-weight: normal;
    margin-bottom: 10px;
}
.conditionTags span {
    display: inline-block;
    height: 32px;
    line-height: 32px;
    padding: 0 30px;
    background-color: #eee;
    font-size: 14px;
    margin-right: 10px;
    border-radius: 16px;
    color: #666;
    margin-bottom:10px;
}