.body {
    background-color: #fff;
    padding-top: 50px;
}
.header {
    position: fixed;
    width: 100%;
    background-color: #449bfe;
    height: 50px;
    text-align: center;
    left: 0;
    top: 0;
    z-index: 999;
}
.header span {
    line-height: 50px;
    color: #fff;
    font-size: 17px;
}
.gobackBox {
    height: 50px;
    width: 50px;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    padding-top: 15px;
}
.gobackBox img {
    height: 20px;
}

.newsBox {
    width: 100%;
}