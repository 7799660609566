.body {
    margin-top: 20px;
    background-color: #fff;
}
.totalNum {
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    margin-bottom: 10px;
}
.body ul {
    list-style: none;
}
.body ul li {
    height: 140px;
    padding: 0 15px 20px 15px;
    background-image: url('../../assets/images/collegeInquiryImg/院校列表的投影.png');
    background-size: 100% 100%;
}
.allClude {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    height: 120px;
    padding: 5px 10px 15px 10px;
}
.flexLeft {
    display: flex;
}
.logoBox {
    width: 100px;
    height: 100px;
    margin-right: 10px;
}
.logoBox img {
    width: 100px;
    height: 100px;
}
.titleAndRank {
    height: 40px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
.titleAndRank span {
    font-weight: 600;
    font-size: 16px;
}
.schoolRank span {
    line-height: 40px;
}
.schoolTags {
    margin-bottom: 5px;
}
.schoolTags span {
    display: inline-block;
    font-size: 12px;
    margin-bottom: 3px;
    padding: 2px 7px;
    background-color: #449bfe;
    color: #fff;
    border-radius: 10px;
    margin-right: 5px;
}
.schoolCateLocal span:nth-child(1) img {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    vertical-align: middle;
    margin-bottom: 2px;
}
.schoolCateLocal span:nth-child(2) img {
    width: 11px;
    height: 14px;
    margin-right: 5px;
    vertical-align: middle;
    margin-bottom: 5px;
}
.schoolCateLocal span {
    color: #999;
    font-size: 12px;
    margin-right: 15px;
}
.schoolRank img {
    width: 11px;
    height: 13px;
    margin-right: 5px;
    vertical-align: middle;
    margin-bottom: 3px;
}
.schoolRank span {
    color: #feb444;
}