
.summary {
    padding-top: 20px;
    position: relative;
}
.circle1 {
    position: absolute;
    height: 8px;
    width: 8px;
    border-radius: 4px;
    left: 20px;
    top: 30px;
    background-color: #449bfe;
}
.circle2 {
    position: absolute;
    height: 8px;
    width: 8px;
    border-radius: 4px;
    right: 20px;
    top: 30px;
    background-color: #449bfe;
}
.summary h2 {
    text-align: center;
}
.introduction {
    padding: 20px;
}
.introduction p {
    text-indent: 2em;
    line-height: 26px;
    font-size: 16px;
    height: 78px;
    color: #777;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.introductionOpen {
    padding: 20px;
}
.introductionOpen p {
    text-indent: 2em;
    line-height: 26px;
    font-size: 16px;
    color: #444;
}

.detailOpen {
    float: right;
    width: 70px;
    background-color: #fff;
    padding: 10px 10px 10px 0;
    text-align: right;
}
.detailOpen span {
    color: #999;
    font-size: 13px;
}
.detailOpen img {
    height: 8px;
    margin-left: 10px;
}

.majorIntroBox {
    padding-top: 10px;
    background-color: #eee;
}
.majorIntro {
    background-color: #fff;
    padding-top: 20px;
    position: relative;
}
.majorIntroDetail {
    display: flex;
    padding: 20px;
}
.majorIntroDetail span {
    width: 80px;
    line-height: 26px;
    font-size: 16px;
}
.majorIntroDetail p {
    flex:1;
    line-height: 26px;
    font-size: 16px;
    color: #888;
}