.body {
    padding-top: 50px;
}
.header {
    height: 50px;
    display: flex;
    background-color: #449bfe;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    padding: 0 50px;
}
.gobackBox {
    height: 50px;
    width: 50px;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    padding-top: 13px;
}
.gobackBox img {
    margin-top: 2px;
    height: 20px;
}
.inputBox {
    margin: 10px 15px 0 0px;
    height: 30px;
    position: relative;
    background-color: #6cacff;
    color:#fff;
    border-radius: 15px;
    padding: 3px 15px 0 40px;
    width: 100%;
}
.inputBox img {
    position: absolute;
    left: 15px;
    top: 8px;
    height: 15px;
}
.inputBox input {
    width: 100%;
    height: 24px;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    outline: none;
    font-size: 14px;
    color: #fff;
}
.inputBox input::-webkit-input-placeholder {
    color: #fff;
}
.header span {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 50px;
    width: 50px;
    text-align: center;
    color: #fff;
}